import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"

import PageHeaderSlim from '../components/pageHeaderSlim/pageHeaderSlim'
import NewsGrid from '../components/newsGrid/newsGrid'
  
export const query = graphql`
  query NewsMediaQuery {
    prismic {
      allNews_medias {
        edges {
          node {
            title
          }
        }
      }
      allNews_articless(sortBy: post_date_DESC) {
        edges {
          node {
            title
            post_excerpt
            thumbnail
            _meta {
              uid
              type
            }
            external_article_link {
              _linkType
              ... on PRISMIC__ExternalLink {
                url
              }
            }
          }
        }
      }
    }
  }
`

const NewsMedia = ({ data }) => {
  const doc = data.prismic.allNews_medias.edges.slice(0,1).pop();
  const newsPosts = data.prismic.allNews_articless.edges;
  if (!doc) return null;

  return (
    <React.Fragment>
      <SEO title="News & Media" />
      <PageHeaderSlim title={doc.node.title} />
      <div className="section-slim">
        <div className="limit-width">
          <NewsGrid articles={newsPosts.map(post => post.node)} feature={true} />
        </div>
      </div>
    </React.Fragment>
  )
}

export default NewsMedia
